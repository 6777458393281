@import "modules/responsive-type.scss";

$primary: #666;/* MAIN COLOR */
$secondary: #007999; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 5px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;

// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 20px;

// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes. 

$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop


@import url('https://fonts.googleapis.com/css?family=Audiowide|Days+One|Fredoka+One|Michroma|Orbitron|Varela+Round');

// font-family: 'Michroma', sans-serif;
// font-family: 'Orbitron', sans-serif;
// font-family: 'Varela Round', sans-serif;
// font-family: 'Fredoka One', cursive;
// font-family: 'Audiowide', cursive;
// font-family: 'Days One', sans-serif;

html, body {
    height: 100%;
}

html {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

p {
    font-family: 'Varela Round', sans-serif;
    font-size: 0.8rem;
}

h1 { 
  font-family: 'Orbitron', sans-serif; 
  font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h2 { 
    font-family: 'Orbitron', sans-serif; 
  font-size: $mod_1*$mod_1*$mod_1 *1rem; 
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h3 { 
  font-size: $mod_1*$mod_1 *1rem;
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1 *$min_font, $mod_2*$mod_2 *$min_font);
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
} 

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill, 
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25), 0 6px 20px 0 rgba(0, 0, 0, 0.2);

    .navbar-nav {
        >li>a {
            font-family: 'Varela Round', sans-serif;
            text-transform:uppercase;
            border-radius: $border-radius;
            text-align: center;
            margin-top: 22px;
            display: flex;
            align-items: center;
            color: $blk;

            @media (max-width: 767px) {
                margin-top: 0;
                padding: 6px 15px;  
                display: inline-block;
            }
            &:focus,
            &:active {
                background: transparent;
                color: $blk;
                outline: 0;
            }
            &:hover {
                background: $primary;
                color: $wht;

                @media (max-width: 1024px) {
                    background: transparent;
                    color: $blk;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/

@mixin btn {
    border: none;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 0.5em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 2em 0em;
    transition: 0.7s ease all;
    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        text-decoration: none;
        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}
.modal-dialog {
    max-width: 500px;
    width: 100%;
    text-align: center;
    margin: 6em auto;

    .close {display: none;}

    .modal-content {
        color: $primary;

        h2 {
            text-align:center;
            font-size: 25px;
        }
    }

    a.btn, a.btn-default, button {
        @include btn
        margin: 0;
        display: block;
    }
}

.modal-header, .modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
  overflow: auto;
  padding-right: 0px !important;
}



/** END LOGIN FORM **/

footer {
    padding: 50px 0px 20px;
    background: $primary;
    color: $footerLinks;
    font-size: 15px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-width: 250px;
    padding: 0.5em;
}

@media(max-width: 767px) {
    .logo {
        max-width: 175px;
    }
    .navbar-header a {
        float: left;
    }
}

@media (max-width: 600px) {
    .logo {
        max-width: 125px;
    }
    .navbar-toggle {
        margin-top: 5px;
    }
}

.top-pad {
    padding: 3em;
    background: white;

    @media (max-width: 990px) {
        padding: 1em;
    }    
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media  (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */ 
    }
}

.terms,
.privacy {
    p {
        font-size: 1rem;
    }
    
    & ol {
        li {
            font-size: 1rem;
        }
        & ol li {
            font-size: 0.85rem;
        }
    }
}

.hrstyle {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}

.borderthis{
    border: 3px solid #0d171b;
}

.roundthis{
    border-radius: $border-radius;
}


.intro{
    // background: url(../img/banner.jpg);
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../img/banner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% 50%;
    padding: 400px 0px;
    
    @media (max-width: 991px) {
        padding: 300px 0px;
    }

    @media (max-width: 767px) {
        padding: 150px 0px;
    }
    
    @media (max-width: 568px) {
        padding: 125px 0px;
    }

    h1{
        color: #f9f9f9;
    }
}

.about{
    
    padding: 25px 0px;

    h1{
        text-transform: uppercase;
        @media (min-width: 991px){
            font-size: 2rem;
        }

        @media (max-width: 767px) {
            text-align: center;
            font-size: 2rem;
        }
    }

    p {
        @media (max-width: 991px) {
            padding-top: 25px;
        }
    }
}

.services{
    margin: 0px auto;
    max-width: 1272px;
    background-color: #1a2d35;
    padding: 25px 0px;
    
    h1{
        color: #f9f9f9;
        
        text-transform: uppercase;
        @media (min-width: 991px){
            font-size: 2rem;
        }

        @media (max-width: 767px) {
            text-align: center;
            font-size: 2rem;
        }
    }

    h2{
        color: #f9f9f9;
       
        text-transform: uppercase;
        @media (min-width: 991px){
            font-size: 1.2rem;
        }

        @media (max-width: 767px) {
            text-align: center;
            font-size: 1.2rem;
        }
    }

    p{
        color: #f9f9f9;
    }
}

.divider{
   padding: 150px 0px;
}


.contact{
    padding: 25px 0px;

    .padTop{
        padding-top: 25px;

        @media (max-width: 767px) {
            padding-top: 0px;
        }
    }

    h1{
        text-transform: uppercase;
        @media (min-width: 991px){
            font-size: 2rem;
        }

        @media (max-width: 767px) {
            text-align: center;
            font-size: 2rem;
        }
    }

    h2{
        text-transform: uppercase;
        @media (min-width: 991px){
            font-size: 1.5rem;
        }

        @media (max-width: 767px) {
            text-align: center;
            font-size: 1.5rem;
        }
    }

    a{
        color: #333;
        text-decoration: none;
        &:hover{
            color: #333;
        }
    }
}